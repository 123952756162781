import React from "react"
import Layout from "../components/layout"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"


const Content = () => (
  <Layout>
  <div className="layout-content">
    <div className="blue-bg">
      <form>
        <h1>Page cannot be found</h1>
      </form>
      <div className="vectors-left">
        <img src={BlueDots} id="blue-dots" alt="" />
        <img src={BlueDot} id="blue-dot" alt="" />
      </div>
      <div className="vectors-right">
        <img src={BlueCicle} id="blue-circle" alt="" />
        <img src={BlueDots2} id="blue-dots-2" alt="" />
      </div>
    </div>
    <div className="wrapper">
      <div className="thank-you">
        <p>Oops! The page you are looking for has been removed or relocated.</p>
      </div>
    </div>
    </div>
  </Layout>
)

export default Content
